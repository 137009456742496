<template>
  <a-card :bordered="false" class="basicPractiseItemPage">
    <div class="header float">
      <h4 class="one_line">{{ itemData.caseNumber }} - {{ itemData.caseName }}</h4>
      <button @click="handleClick">开始练习</button>
    </div>
    <div class="info all">
      <p>{{ itemData.departmentName }}</p>
      <p>分值：{{ itemData.totalScore }}</p>
      <p v-show="itemData.lastDate">上次得分：{{ itemData.lastScore }}</p>
      <p v-show="itemData.lastDate">上次练习时间：{{ itemData.lastDate }}</p>
    </div>
    <div class="info all">
      <p>{{ itemData.keywords }}</p>
    </div>
    <div class="details">
      <div class="item">
        <h4>简述</h4>
        <div class="decs">{{ itemData.patientDescription || "暂无" }}</div>
      </div>
      <div class="item" v-show="learnController == true">
        <h4>学习目标</h4>
        <div class="decs">{{ itemData.learningObjectives || "暂无" }}</div>
      </div>
      <div class="item" v-show="learnController == true">
        <h4>建议麻醉计划</h4>
        <div class="decs">{{ itemData.anesthesiaPlan || "暂无" }}</div>
      </div>
      <div class="item" v-show="learnController == true">
        <h4>临床要点</h4>
        <div class="decs">{{ itemData.clinical || "暂无" }}</div>
      </div>
      <div class="item" v-show="learnController == true">
        <h4>病例作者</h4>
        <div class="decs">{{ itemData.author || "暂无" }}</div>
      </div>
      <div class="item" v-show="learnController == true">
        <h4>其他处理</h4>
        <div class="decs">{{ itemData.other || "暂无" }}</div>
      </div>
    </div>
    <!-- <div class="detail" :class="learnController ? '' : 'one_line'">
      {{ itemData.learningObjectives }}
    </div> -->
    <button class="detailController" @click="learnController = !learnController">
      {{ learnController ? "点击收起学习内容" : "点击展开学习内容" }}
    </button>
  </a-card>
</template>
<script>
export default {
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      learnController: false,
    };
  },
  methods: {
    async handleClick() {
      if (!this.itemData.caseId) {
        this.$notification.error({
          message: "警告",
          description: `该基础练习未配置完整,请联系管理员`,
          duration: null,
        });
        return;
      }
      try {
        let res = await this.$http.get(this.$interface.examCheckStartCount);
        this.$router.push(`/practice?caseId=${this.itemData.caseId}`);
      } catch (err) {}
    },
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>
